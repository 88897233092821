import React from "react"
import firebase from 'firebase/compat/app';

import { CardHeader, Card, CardBody, CardTitle, CardSubtitle, CardLink, CloseButton } from "reactstrap";

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Likes from "./Likes";
import DeleteModal from "./DeleteModal";
import Adsterra2 from "./Adsterra2";

const messageConverter = {
    toFirestore(message) {
        return {
            ...message
        };
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id
        };
    }
};

export default function Post(props) {

    const firestore = firebase.firestore();
    const likesRef = firestore.collection("posts").doc(props.content.id).collection("likes").withConverter(messageConverter);
    const [likes] = useCollectionData(likesRef);

    const isImage = ['.gif', '.jpg', '.jpeg', '.png', '.webp'];
    const isVideo = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4', '.mov']

    let classString = likes && props.user && likes.some(e => e.uid === props.user.uid) && "btn btn-outline-primary btn-sm rounded-pill active";

    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = () => setModalOpen(!modalOpen);

    // console.log(props.content.title)

    return (
        <WhatToDisplay />
    )

    function WhatToDisplay() {

        let homepage = !props.mood;
        let displayLust = props.modalData && Object.keys(props.modalData) && Object.keys(props.modalData).length !== 0 && props.modalData.isOver18;

        if ((homepage && displayLust) || props.mood) {
            return (<Display />)
        }
        // filter out lust mood from homepage
        else if (homepage && !displayLust && props.content.mood !== "lust") {
            return (<Display />)
        }
    }

    function Display() {
        return (
            <>
                <div id={props.content.id} className='row mb-4 justify-content-center'>
                    <div className='col-md-8'>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">
                                    <a href={`/post/${props.content.id}`}>{props.content.title}</a>
                                    {/* {props.content.title} */}
                                    {props.user && props.user.uid === props.content.uid && <CloseButton style={{ float: "right" }} title="Delete Post?" onClick={toggle} />}
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    {props.content.email.match(/(.*)@gmail.com/)[1]}
                                </CardSubtitle>
                            </CardHeader>
                            {
                                props.content.photoURL &&
                                <DisplayImageOrVideo />
                            }
                            <CardBody>
                                <div className="cardtext">
                                    {props.content.body.split("¶").map((line, index) => <div key={index}>{line}</div>)}
                                </div>
                                <CardLink>
                                    <Likes
                                        classstring={classString}
                                        likes={likes} user={props.user}
                                        content={props.content}
                                    />
                                </CardLink>
                                <CardLink title='Coming Soon!'>
                                    Comments
                                </CardLink>
                                {!props.mood &&
                                    <CardLink
                                        href={`/mood/${props.content.mood}`} className="btn btn-outline-primary btn-sm rounded-pill active">
                                        {props.content.mood}
                                    </CardLink>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                {
                    // !(props.index % 2) &&
                    // <div className='row mb-4 justify-content-center'>
                    //     <div className='col-md-4'>
                    //         {/* <InFeedAd /> */}
                    //         {/* <Adsterra /> */}
                    //         {/* <Adsterra2 slot={"132f4273c7ea030383efac7188686b0d"} index={props.index} /> */}
                    //     </div>
                    // </div>
                }
                <DeleteModal modalOpen={modalOpen} toggle={toggle} deleteModalHandler={props.deleteModalHandler} content={props.content} />
            </>
        )
    }

    function DisplayImageOrVideo() {
        if (isImage.includes(props.content.photoURL.match(/(.*)(\..*)(\?.*)/i)[2].toLowerCase())) {
            return (
                <img
                    alt={props.content.title}
                    src={props.content.photoURL}
                    width="100%"
                />
            )
        }
        else if (isVideo.includes(props.content.photoURL.match(/(.*)(\..*)(\?.*)/i)[2].toLowerCase())) {
            return (
                <video
                    width="100%"
                    loop
                    playsInline
                    muted
                    controls
                    preload="metadata"
                >
                    <source src={props.content.photoURL+"t=0.5"} />
                    Your browser does not support the video tag.
                </video>
            )
        }
    }
}